<template>
  <div class="product">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <a
      ><router-link to="/about"
        ><b-icon-arrow-left-circle />Voltar</router-link
      ></a
    >
    <ProductCard
      :name="this.product.name"
      :ean="this.product.ean"
      :sku="this.product.sku"
      :stock="this.product.stock"
      :weight="this.product.weight"
      :price="this.product.price"
      :avarage="this.product.average_price"
      :iva="this.product.tax_iva"
      :img="this.img"
      :notesWarehouse="this.product.note_warehouse"
      :notesFinish="this.product.note_finish"
      :priceRegular="this.product.regular_price"
      :id="this.id"
      :country="this.country"
      :hscode="this.product.hscode"
      :eanhold="this.product.ean"
      :sku_old="this.product.sku"
      :validity="this.product.validity"
      :stockStatus="this.product.stock_status"
      :priceOption="this.product.price_options"
      :aerosol="this.product.aerosol"
      :brand="this.selectedBrand"
      :allBrands="this.allBrands"
    />
    <div class="card">
      <h3>Info Sites</h3>
      <br />
      <div class="col-md-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >Origem</span
            >
          </div>
          <input
            type="text"
            class="form-control form-control-sm"
            id="ean"
            v-model="product.p_custo_origem"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >P. Custo</span
            >
          </div>
          <input
            type="text"
            class="form-control form-control-sm"
            id="ean"
            v-model="product.p_custo"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >Descontos</span
            >
          </div>
          <input
            type="text"
            class="form-control form-control-sm"
            id="discounts"
            v-model="discountsText"
            disabled
          />
        </div>
      </div>
      <br />
      <form v-on:submit.prevent="updateExtraInfo()" class="row">
        <div class="col" style="text-align: center">
          <p style="font-size: larger"><b>Idivia</b></p>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Stock</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_stock"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Regular Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_reg_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Sale Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_sale_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Auto Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_auto_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Desconto Ativo</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="product.active_discount_idivia"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Discount Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_discount_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Manual Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.idivia_manual_price"
              />
            </div>
          </div>
        </div>
        <div class="col" style="text-align: center">
          <p style="font-size: larger"><b>Kiooli</b></p>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Stock</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_stock"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Regular Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_reg_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Sale Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_sale_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Auto Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_auto_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Desconto Ativo</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="product.active_discount_kiooli"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Discount Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_discount_price"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Manual Price</span
                >
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="ean"
                v-model="extraInfo.kiooli_manual_price"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary">Atualizar</button>
          </div>
        </div>
      </form>
    </div>
    <div style="text-align: center">
      <button id="trans" class="btn btn-primary" @click="change()">
        Transações
      </button>
      <button id="purch" class="btn btn-primary" @click="change()">
        Encomendas
      </button>
    </div>
    <div class="card">
      <span v-if="transaction">
        <h3>Transações</h3>
        <div class="row" id="filters">
          <div class="col">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Data</span
                >
              </div>
              <date-picker
                v-model="date"
                format="YYYY-MM-DD"
                value-type="format"
                :lang="lang"
                range
                @change="getTrans(date[0], date[1])"
              ></date-picker>
            </div>
          </div>
        </div>
        <!-- TABELA COM TODOS OS PRODUTOS -->
        <div class="table">
          <b-table
            striped
            hover
            :items="trans"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            thead-class="text-white"
            small
          >
            <template #cell(from_id)="data">
              <span
                id="route"
                v-if="data.item.quantidade < 0"
                @click="goRoute('sair', data.item.from_id)"
                >{{ data.item.from_id }}</span
              >
              <span
                id="route"
                v-else-if="data.item.quantidade > 0"
                @click="goRoute('receber', data.item.from_id)"
                >{{ data.item.from_id }}</span
              >
            </template>
            <template #cell(date)="data">
              {{ splitDate(data.item.date) }}
            </template>
            <template #cell(quantidade)="data">
              <span v-if="data.item.quantidade < 0">
                <span style="color: red; font-size: larger"><b>⬆</b></span>
                {{ Math.abs(data.item.quantidade) }}</span
              >
              <span v-else-if="data.item.quantidade > 0"
                ><span style="color: green; font-size: larger"><b>⬇</b></span>
                {{ Math.abs(data.item.quantidade) }}</span
              >
              <span v-else-if="data.item.quantidade == 0">
                {{ Math.abs(data.item.quantidade) }}</span
              >
            </template>
            <template #cell(supplier)="data">
              <span v-if="data.item.comment == undefined">{{
                data.item.supplier
              }}</span>
              <span v-else
                >{{ data.item.supplier }} ({{ data.item.comment }})</span
              >
            </template>
          </b-table>
        </div>
        <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
        <b-pagination
          class="customPagination"
          v-model="currentPage"
          pills
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="center"
        ></b-pagination>
      </span>
      <span v-else>
        <h3>Encomendas</h3>
        <div class="table">
          <b-table
            striped
            hover
            :items="productPurchases"
            :fields="fields2"
            :per-page="perPage"
            :current-page="currentPage"
            thead-class="text-white"
            small
          >
            <template #cell(date)="data">
              {{ splitDate(data.item.date) }}
            </template>
            <template #cell(status)="data">
              <span v-if="data.item.status == 'confirmed'">Confirmada</span>
              <span v-else-if="data.item.status == 'pendent'">Pendente</span>
              <span v-else-if="data.item.status == 'canceled'">Cancelada</span>
            </template>
          </b-table>
        </div>
        <b-pagination
          class="customPagination"
          v-model="currentPage"
          pills
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="center"
        ></b-pagination>
      </span>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
/* import { useRoute } from "vue-router"; */
export default {
  components: {
    ProductCard,
    Loading,
  },
  data: function () {
    return {
      perPage: 10,
      currentPage: 1,
      criteria: '',
      rows: 1,
      product: [],
      products: '',
      id: '',
      img: '',
      stock: 0,
      loading: false,
      trans: [],
      date: '',
      productPurchases: [],
      transaction: true,
      extraInfo: [],
      selectedBrand: '',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
      fields: [
        {
          key: 'from_id',
          label: 'Order ID',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'supplier',
          label: 'Fornecedor/Motivo',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'quantidade',
          label: 'Movimento',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'stock',
          label: 'Stock',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'preco',
          label: 'Preço',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
      fields2: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'supplier',
          label: 'Fornecedor/Motivo',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'qty_order',
          label: 'Quantidade Pedida',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'qty_confirmed',
          label: 'Quantidade Confirmada',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'status',
          label: 'Estado',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    calcStock(id) {
      return Number(this.trans[id].stock) - Number(this.trans[id].quantidade);
    },
    // async updateProdct() {
    //   this.loading = true;
    //   try {
    //     this.id = this.$route.params.id;
    //     await this.$store.dispatch('putDataProduct', {
    //       id: this.id,
    //     });
    //   } catch (err) {
    //     alert(err);
    //   }
    //   this.getProduct();
    // },
    goRoute(state, id) {
      if (state == 'receber') {
        // this.$router.push(`/receptionsProducts/${id}/updateData`);
        window.open(
          `https://${window.location.host}/receptionProducts/${id}/update`,
          '_blank'
        );
      } else if (state == 'sair') {
        // this.$router.push(`/orders/${id}`);
        window.open(`https://${window.location.host}/orders/${id}`, '_blank');
      }
    },
    splitDate(date) {
      let split = date.split('T');
      let split2 = split[1].split('.');
      return split[0] + ' ' + split2[0];
    },
    async getTrans(data_i, data_f) {
      console.log('SKU: ', this.product.sku);
      if (data_i == undefined || data_f == undefined) {
        let today = new Date();
        data_f = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        today.setTime(today.getTime() - 90 * 24 * 60 * 60 * 1000);
        data_i = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
      }
      try {
        await this.$store.dispatch('getProductTrans', {
          id: this.id,
          start_date: data_i,
          final_date: data_f,
        });
        this.trans = this.getProdTrans;
        this.rows = this.trans.length;
        console.log(this.trans);
      } catch (err) {
        alert(err);
      }
    },
    async getExtraInfo() {
      try {
        await this.$store.dispatch('getProductExtraInfo', {
          id: this.id,
        });
        this.extraInfo = this.getProdExtraInfo;
        console.log('EXTRA EXTRA', this.extraInfo);
      } catch (err) {
        alert(err);
      }
    },
    async updateExtraInfo() {
      this.$swal({
        title: 'Tem a certeza que quer atualizar os dados?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updateProductExtraInfo', {
              id: this.id,
              idivia_manual_price: this.extraInfo.idivia_manual_price,
              kiooli_manual_price: this.extraInfo.kiooli_manual_price,
            });
            this.$swal('Completado', 'Dados atualizados!', 'success');
          } catch (error) {
            alert(error);
          }
        }
      });
      console.log('UPDATE', this.extraInfo);
    },
    async getProduct() {
      try {
        this.id = this.$route.params.id;
        await this.$store.dispatch('fetchProductID', {
          id: this.id,
        });
        this.product = this.getProduct_ID.data.product[0];
        console.log('AQUI', this.product);
        this.extraInfo = this.getProduct_ID.data.info[0];
        // this.product = this.products.find((product) => product.id_backoffice == this.id);
        console.log('AQUI', this.product, this.extraInfo);
        this.img = this.product.img;
        this.stock = this.product.stock;
      } catch (err) {
        alert(err);
      }
    },
    async change() {
      this.transaction = !this.transaction;
    },
    async purchaseProduct() {
      try {
        await this.$store.dispatch('productPurchase', {
          id: this.id,
        });
        this.productPurchases = this.getProductPurchase;
        console.log(this.productPurchases);
      } catch (err) {
        alert(err);
      }
    },
    async brands() {
      try {
        await this.$store.dispatch('getAllBrands');
        console.log(this.getBrands);
        this.allBrands = this.getBrands;
        this.selectedBrand = await this.allBrands.find(
          (brand) => brand.id == this.product.brand_id
        );
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
  },

  computed: {
    ...mapGetters(['getProduct_ID']),
    ...mapGetters(['getProdTrans']),
    ...mapGetters(['getProductPurchase']),
    ...mapGetters(['getBrands']),
    // ...mapGetters(['getProdExtraInfo']),
    discountsText() {
      if (this.product.discounts == undefined) return;
      return this.product.discounts.join(' / ');
    },
  },
  async created() {
    /* this.updateProdct(); */
    this.loading = true;
    await this.getProduct();
    await this.getTrans();
    await this.purchaseProduct();
    await this.brands();
    // await this.getExtraInfo();
    this.loading = false;
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: #ffc6c6;
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
a {
  margin: 25px;
  padding-top: 20px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}
a:hover {
  color: gray;
}

#route {
  color: blue;
}

#route:hover {
  text-decoration: underline;
  cursor: pointer;
}

.product {
  margin-top: 30px;
}
#filters {
  align-self: center;
}

.table {
  margin-top: 30px;
}
#trans {
  margin-right: 20px;
}
#purch {
  margin-left: 20px;
}

h3 {
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
img {
  max-width: 80%;
}
.col-md-6 {
  margin-bottom: 5px;
}
.input-group-text {
  size: 100px;
  background-color: rgb(74, 106, 192);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px; /* Adjust the width as needed */
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
#infinity:hover {
  cursor: pointer;
  color: aqua;
}
.col {
  width: 50%;
  align-items: center;
}
.col-md-6 {
  margin-left: 25%;
}
</style>
